// todo - these should be set by build environment variables
export const ENDPOINTS = {
  // test environment API endpoints
  TEST: {
    EXTRUSIONAPI: 'http://52.36.206.140',
    ESTIMATEAPI: 'http://35.163.219.149',
    SIMULATIONAPI: 'http://35.163.219.149',
  },
  // dev environment API endpoints
  DEV: {
    EXTRUSIONAPI: 'http://35.166.12.141',
    ESTIMATEAPI: 'http://35.85.217.171',
    SIMULATIONAPI: 'http://35.85.217.171',
  },
};
